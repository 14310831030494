
import { Vue, Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import { ContratoService, EmpreendimentoService, PessoaService, SituacaoContratoService } from '@/core/services/cadastros';
import { Contrato, Pessoa } from '@/core/models/cadastros';
import { DespesaService, TipoContratoService, TipoProdutoContratoService } from '@/core/services/financeiro';
import { Despesa } from '@/core/models/financeiro';
import { EnumOrigemDespesa } from "@/core/models/financeiro/OrigemDespesa";

@Component
export default class ListaContrato extends mixins(Vue,ListPage) {
  item = new Contrato(); 

  filter = new Relatorio();
  service =  new ContratoService();

  dialogCadastro: boolean = false;
  dialogGerenciador:boolean = false;
  dialogVisualizar:boolean = false;
  dialogDigital:boolean = false;
  dialogCadastroDespesa:boolean = false;
  dialogAditar = false;
  overlay:boolean = false; 
  loading: boolean = false;
  totalLista: number = 0;
  lista:any = [];

  contratoDigital = new Contrato();
  despesa = new Despesa();
  cliente = new Pessoa();

  titulo: string = 'Contrato';
  subTitulo: string = 'Lista de Contratos cadastrados no Sistema';

  options: any = {
    itemsPerPage: 15 
  };  

  sheet: boolean = false; 
  empreendimentos:any = [];
  tiposProdutos = [];
  listaGrupos: any = [] = [];
  listaUnidades: any = [] = [];
  listaUnidadesAdicionais: any = [] = [];
  pessoas = [];
  tipos = [];
  situacaoContrato = [];

  headers: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Nº do Contrato", value: "numero"},
    { text: "Cliente", value: "clienteNome", sortable: false }, // aqui é uma lista de clientes
    { text: "Situação", value: "situacao.nome"},
    { text: "Empreendimento", value: "empreendimento.nome"},
    { text: "Grupo", value: "unidadePrincipal.grupo/nome"},
    { text: "Unidade", value: "unidadePrincipal.nome"},
    { text: "Data do Contrato", value: "data"},
  ]; 

  
   breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Lista de Contratos', disabled: true, href: '/financeiro/painel/despesas'}
  ]
  
  @Watch("options",{ deep: true })
  Atualizar() {
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
  
    this.loading = true;
    this.service.ListarContratosAtualizados(page,itemsPerPage, sortBy.length == 0 ? ['numero'] : sortBy, sortBy.length == 0 ? [true] : sortDesc, search, columns, this.filter, 'Clientes, UnidadePrincipal.Grupo').then(
      (res) => { 
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      }, 
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }
    ).finally(() => (this.loading = false));
  }

  Visualizar(item){
    this.overlay = !this.overlay;

    this.service.ObterPorId(item.id,"TipoAditamento, Receitas.Tipo, Reserva, TipoIndice, TipoProduto,Empresa,Empreendimento, UnidadePrincipal.Grupo, Clientes.Cliente, Corretores.Corretor, Observacoes, Receitas.Parcelas.Situacao, Receitas.Parcelas.Baixas.FormaPagamento, HistoricoSituacoes.Situacao, HistoricoSituacoes.Usuario, Observacoes.Usuario").then((res) => {
      this.item = res.data;
      this.dialogVisualizar = true; 
    },
    (err) => {
      if (!err.response) {
        this.$swal("Aviso", "Não foi possível acessar a API", "error");
      } else if (err.response.status == 403) {
        this.$swal("Aviso", err.response.data.message, "warning" );
      } else {
        this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
      }
    }
   ).finally(() => {this.overlay = false}) 
  }  
   
  Consultar() {
    this.options.page = 1;
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;

    this.service.ListarContratosAtualizados(page,itemsPerPage, sortBy.length == 0 ? ['numero'] : sortBy, sortBy.length == 0 ? [true] : sortDesc,search,columns, this.filter, '').then(
      (res) => { 
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }).finally(() => (this.loading = false));
    
    this.sheet = false;
  }  
 
  ObterUnidades() {
    new EmpreendimentoService().ListarUnidade(this.filter.grupoId).then(
      (res) => {
        this.listaUnidades = res.data
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }
    )
  }

  ObterGrupos() {
    new EmpreendimentoService().ListarGrupo(this.filter.empreendimentoId).then(
      (res) => {
        this.listaGrupos = res.data
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }
    )
  } 
 
  Novo(){
    this.item = new Contrato;
    this.dialogCadastro = true;  
  }
    
  Cancelar(item){
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja cancelar o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.Cancelar(item.id).then(
          (res) => {
            if (res.status == 200) {
              return res;
            }
          },  
          (err) => {
            if (!err.response) {
              this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } else if (err.response.status == 403) {
              this.$swal("Aviso", err.response.data.message, "warning" );
            } else {
              this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
            }
          }
        );
      }, 
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      if (result.value) {
        this.$swal(
          "Aviso",
          result.value.data,
          result.value.status == 200 ? "success" : "warning"
        );
        this.Atualizar();
      }  
    }); 
  } 

  ReverterCancelamento(item){
     this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja reverter o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.ReverterCancelamento(item.id).then(
          (res) => {
            if (res.status == 200) {
              return res;
            }
          },  
          (err) => {
            if (!err.response) {
              this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } else if (err.response.status == 403) {
              this.$swal("Aviso", err.response.data.message, "warning" );
            } else {
              this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
            }
          }
        );
      }, 
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      if (result.value) {
        this.$swal("Aviso",result.value.data,result.value.status == 200 ? "success" : "warning");
        this.Atualizar();
      }
    });   
  }
   
  ReverterAditamento(item){
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja reverter o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.Reverter(item.id).then(
          (res) => {
            if (res.status == 200) {
              return res;
            }
          },  
          (err) => this.$swal("Aviso", err.response.data, "error")
        );
      }, 
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result:any) => {
      if (result.value) {
        this.$swal(
          "Aviso",
          result.value.data,
          result.value.status == 200 ? "success" : "warning"
        );
        this.Atualizar();
      }
    }); 
  }
   
  Gerenciar(item) {  
    this.overlay = !this.overlay;
      this.service.ObterPorId(item.id, "Receitas.Parcelas.Transacoes.UsuarioBoletoGerado, UnidadesAdicionais.Unidade, ContratosDigitais.Tipo, ContratosDigitais.Situacao, ContratosDigitais.Signatarios.Cliente, ContratosDigitais.Signatarios.Situacao, Receitas.Parcelas.AntecipacaoAmortizacao.Itens,TipoAmortizacao, Receitas.Parcelas.AntecipacaoComprovante, Observacoes, Receitas.Parcelas.Situacao, Receitas.Parcelas.Baixas.FormaPagamento,TipoIndice").then(res => {
        this.item = new Contrato(res.data);   
        this.dialogGerenciador = true;
      },
       (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      } 
    ).finally(() => {this.overlay = false}) 
  }  

  GerarDespesa(item){
    if(item.despesaId > 0){
      const despesaService = new DespesaService();
        despesaService.ObterPorId(item.despesaId, 'Parcelas, Classificacoes').then(
        (res) => {
          this.despesa = res.data;
          this.despesa.dataEmissao = new Date(res.data.dataEmissao).yyyymmdd();
        },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        })
      
      }else{
        this.despesa = new Despesa();
        this.despesa.origemId = EnumOrigemDespesa.Contrato;
        this.despesa.contratoId = item.id;
        this.despesa.fornecedorId = item.clienteId;
        this.despesa.empreendimentoId = item.empreendimentoId;
      }

    this.dialogCadastroDespesa = true;
  }
    
  Aditar(item) {  
    this.overlay = !this.overlay;
    
    this.service.ObterPorId(item.id, "Empresa, Empreendimento,Clientes, Corretores, Receitas.Parcelas.Baixas, Observacoes, Tipo, UnidadePrincipal.Grupo, UnidadesAdicionais").then((res) => {
      this.item = new Contrato(res.data);
      this.item.data = new Date().yyyymmdd();
      this.dialogAditar = true;
    },
     (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }
    ).finally(() => {this.overlay = false})  
  }

  situacaoColor(item){ 
    if(item.situacaoId == 1)
      return "blue lighten-3";

      else if(item.situacaoId == 2) 
      return "orange lighten-1";

      else if(item.situacaoId == 3)
      return "deep-purple lighten-1";

      else if(item.situacaoId == 4)
      return "red lighten-1";
  
      else if(item.situacaoId == 5)
      return "grey lighten-1";

      else if(item.situacaoId == 6) 
      return "teal lighten-1";

      else if(item.situacaoId == 7)
      return "yellow lighten-1";
  }
 
  ResetFiltro(){
    this.filter = {numero:0,  sequencia:0, tipoProdutoId:0,situacaoId:0, tipoId:0, data:'', empreendimentoId:0, grupoId:0, unidadePrincipalId:0, clienteIds:0, email:''}
  }

  mounted(){
    const situacaoContratoService = new SituacaoContratoService();
    situacaoContratoService.ListarTudo().then(
      res => {
        this.situacaoContrato = res.data.items
      }
    )

    const pessoaService = new PessoaService();
    pessoaService.Listar(-1, -1, ['nome'],[],'',[], '', '' , 'id, Nome, CnpjCpf,nomeCnpjCpf, Email', '').then(
      res => {
        this.pessoas = res.data.items
      }
    )

  new TipoProdutoContratoService().ListarTudo().then(
    res => {
      this.tiposProdutos = res.data.items
    }) 

  new EmpreendimentoService().Listar(-1, -1, ['nome'], [], undefined, [], "", '', "Id,Nome", "").then(
    res => {
      this.empreendimentos = res.data.items
    })

  new TipoContratoService().ListarTudo().then(
    res => {
      this.tipos = res.data.items
    })
  } 
} 
           
class Relatorio {
  numero: number = 0;
  sequencia: number = 0;
  tipoProdutoId:number = 0;
  situacaoId:number = 0;
  tipoId: number =  0;
  data:string = "";
  empreendimentoId: number = 0;
  grupoId: number= 0; 
  unidadePrincipalId:number= 0;
  clienteIds = 0;
  email:string = "";
}
